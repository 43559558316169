import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SiteText from '../components/sitetext';


const Privacy = () => {
  const site = useStaticQuery(graphql`
    query PrivacyDataQuery
    {
      wordpressPage(wordpress_id: { eq: 24 }) {
        title
        content
      }
    }
    `);


  // We can use set innerHTML here, as the content comes from our CMS
  return (
    <Layout title="Datenschutz Erklärung">
      <SiteText>
        <h1>{site.wordpressPage.title}</h1>
        <div id="mainText" dangerouslySetInnerHTML={{ __html: site.wordpressPage.content }} />
      </SiteText>
    </Layout>
  );
};
export default Privacy;
